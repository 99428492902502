var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { class: _vm.mobileScreen ? `pa-0` : `` },
    [
      _c(
        "div",
        { staticClass: "py-3", staticStyle: { "max-width": "1100px" } },
        [
          _c("submission-alert-message", {
            ref: "banner",
            attrs: {
              "should-scroll-to-top": "",
              message: _vm.badExamFormMessage[_vm.$language],
            },
            model: {
              value: _vm.showAlertCondition,
              callback: function ($$v) {
                _vm.showAlertCondition = $$v
              },
              expression: "showAlertCondition",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "m-light-card" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "7" } },
                [
                  _c(
                    "v-tabs",
                    {
                      model: {
                        value: _vm.currentStep,
                        callback: function ($$v) {
                          _vm.currentStep = $$v
                        },
                        expression: "currentStep",
                      },
                    },
                    [
                      _c("v-tab", [
                        _vm._v(
                          " " +
                            _vm._s(!_vm.$language ? "1. Essay" : "১. রচনা") +
                            " "
                        ),
                      ]),
                      _c("v-tab", [
                        _vm._v(
                          " " +
                            _vm._s(
                              !_vm.$language
                                ? "2. Questions & Answers"
                                : "২. প্রশ্ন এবং উত্তর"
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pr-7 d-flex justify-end align-center",
                  attrs: { cols: "5" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.advanced,
                          expression: "advanced",
                        },
                      ],
                      staticClass: "mr-4",
                      attrs: { outlined: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.preview()
                        },
                      },
                    },
                    [_vm._v("Preview ")]
                  ),
                  _c("v-switch", {
                    attrs: { dense: "", label: "Advanced" },
                    model: {
                      value: _vm.advanced,
                      callback: function ($$v) {
                        _vm.advanced = $$v
                      },
                      expression: "advanced",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.currentStep,
                callback: function ($$v) {
                  _vm.currentStep = $$v
                },
                expression: "currentStep",
              },
            },
            [
              _c(
                "v-tab-item",
                [
                  !_vm.modeLinked
                    ? _c("essay-form", {
                        ref: "essayForm",
                        attrs: {
                          "course-id": this.$courseId(),
                          "is-valid": _vm.essayok,
                        },
                        on: {
                          "update:isValid": function ($event) {
                            _vm.essayok = $event
                          },
                          "update:is-valid": function ($event) {
                            _vm.essayok = $event
                          },
                          "essay-update": _vm.updateEssayContent,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "top-right",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-end align-center",
                                    },
                                    [
                                      _vm.advanced
                                        ? _c("v-switch", {
                                            staticClass: "mt-0",
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              label: "Link Group Question",
                                            },
                                            model: {
                                              value: _vm.modeLinked,
                                              callback: function ($$v) {
                                                _vm.modeLinked = $$v
                                              },
                                              expression: "modeLinked",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1301078159
                        ),
                        model: {
                          value: _vm.essay,
                          callback: function ($$v) {
                            _vm.essay = $$v
                          },
                          expression: "essay",
                        },
                      })
                    : _c("linkable-group-question-list", {
                        on: {
                          select: (v) => {
                            _vm.linkingId = v.id
                            _vm.linkingBody = v.body
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "top-right",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-end align-center",
                                  },
                                  [
                                    _vm.advanced
                                      ? _c("v-switch", {
                                          staticClass: "mt-0",
                                          attrs: {
                                            dense: "",
                                            "hide-details": "",
                                            label: "Link Group Question",
                                          },
                                          model: {
                                            value: _vm.modeLinked,
                                            callback: function ($$v) {
                                              _vm.modeLinked = $$v
                                            },
                                            expression: "modeLinked",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                  _c("tab-navigation", {
                    attrs: { language: _vm.$language, "previous-disabled": "" },
                    on: { next: _vm.nextStep, previous: _vm.previousStep },
                  }),
                ],
                1
              ),
              _c("v-tab-item", [
                _vm.advanced
                  ? _c(
                      "div",
                      [
                        _c("advanced-question-answer-form", {
                          model: {
                            value: _vm.questionGroup,
                            callback: function ($$v) {
                              _vm.questionGroup = $$v
                            },
                            expression: "questionGroup",
                          },
                        }),
                        _c("tab-navigation", {
                          attrs: { language: _vm.$language },
                          on: {
                            next: _vm.nextStep,
                            previous: _vm.previousStep,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "right",
                                fn: function () {
                                  return [
                                    _c(
                                      "m-rounded-button",
                                      {
                                        attrs: {
                                          outlined: "",
                                          color: "red accent-3",
                                          "data-qa": "cancel-button",
                                        },
                                        on: { click: _vm.cancel },
                                      },
                                      [
                                        _c("m-icon", { attrs: { left: "" } }, [
                                          _vm._v("cancel"),
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              !_vm.$language
                                                ? "Cancel"
                                                : "বাতিল করুন"
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "m-rounded-button",
                                      {
                                        staticClass: "ml-2",
                                        attrs: { loading: _vm.creating },
                                        on: { click: _vm.saveAdvanced },
                                      },
                                      [
                                        _c("m-icon", { attrs: { left: "" } }, [
                                          _vm._v("save_alt"),
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              !_vm.$language
                                                ? "Save"
                                                : "সেভ করুন"
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1896656748
                          ),
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c("question-set-form", {
                          ref: "questionSetForm",
                          attrs: { "is-valid": _vm.qaok },
                          on: {
                            "update:isValid": function ($event) {
                              _vm.qaok = $event
                            },
                            "update:is-valid": function ($event) {
                              _vm.qaok = $event
                            },
                          },
                          model: {
                            value: _vm.questions,
                            callback: function ($$v) {
                              _vm.questions = $$v
                            },
                            expression: "questions",
                          },
                        }),
                        _c("tab-navigation", {
                          attrs: { language: _vm.$language },
                          on: {
                            next: _vm.nextStep,
                            previous: _vm.previousStep,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function () {
                                return [
                                  _c(
                                    "m-rounded-button",
                                    {
                                      attrs: {
                                        outlined: "",
                                        color: "red accent-3",
                                        "data-qa": "cancel-button",
                                      },
                                      on: { click: _vm.cancel },
                                    },
                                    [
                                      _c("m-icon", { attrs: { left: "" } }, [
                                        _vm._v("cancel"),
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            !_vm.$language
                                              ? "Cancel"
                                              : "বাতিল করুন"
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "m-rounded-button",
                                    {
                                      staticClass: "ml-2",
                                      attrs: { loading: _vm.creating },
                                      on: { click: _vm.save },
                                    },
                                    [
                                      _c("m-icon", { attrs: { left: "" } }, [
                                        _vm._v("save_alt"),
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            !_vm.$language ? "Save" : "সেভ করুন"
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          model: {
            value: _vm.showPreview,
            callback: function ($$v) {
              _vm.showPreview = $$v
            },
            expression: "showPreview",
          },
        },
        [
          _c(
            "question-previewer",
            _vm._b(
              {
                on: {
                  close: function ($event) {
                    _vm.showPreview = false
                  },
                },
              },
              "question-previewer",
              _vm.previewProps,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }