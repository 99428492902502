<template>
  <v-container :class="!aboveHalfFHD ? `pa-0` : ``">
    <page-title
      :page-title="
        !$language ? 'Create Group Question' : 'গ্রুপ প্রশ্ন তৈরি করুন'
      "
      class="mt-5"
    >
      <template #left>
        <v-btn
          class="mr-2"
          color="black"
          icon
          large
          @click="
            () => {
              $router.push({
                name: 'question-list'
              });
            }
          "
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
      </template>
    </page-title>

    <group-question-create
      v-if="aboveHalfFHD"
      @saved="goToList"
      @cancel="goToList"
    >
    </group-question-create>
    <div v-else style="height: 60vh; color: grey;" class="center-content">
      Switch to a bigger screen to use this feature.
    </div>
  </v-container>
</template>

<script>
import GroupQuestionCreate from "#ecf/question-bank/components/GroupQuestion/Create";
import { TopicsProvider } from "#ecf/question-bank/mixins";

export default {
  name: "GroupQuestionCreatePage",
  components: {
    GroupQuestionCreate
    /*ImageInput,
    QuestionDetails,*/
  },
  // inject: ["$courseOwnerId", "$courseId"],
  mixins: [TopicsProvider],
  data: () => ({
    essay: { text: undefined, image: undefined },
    currentQuestion: {},
    questions: [],
    questionFormVisible: false,
    editing: false,
    editingIndex: undefined,
    questionToView: {},
    questionViewDialog: false,
    saving: false
  }),
  computed: {
    aboveHalfFHD() {
      return this.$vuetify.breakpoint.width >= 960;
    }
  },
  methods: {
    async goToList() {
      await this.$router.replace({ name: "question-list" });
    },
    async addQuestion() {
      if (this.editing) {
        this.questions.splice(this.editingIndex, 1, this.currentQuestion);
      } else {
        this.questions.push(this.currentQuestion);
      }
      this.currentQuestion = {};
      this.questionFormVisible = false;
    },
    showQuestionForm() {
      this.questionFormVisible = true;
    },
    cancelQuestionAdd() {
      this.questionFormVisible = false;
    },
    editQuestion(i) {
      this.editing = true;
      this.editingIndex = i;
      this.currentQuestion = this.questions[i];
      this.questionFormVisible = true;
    },
    deleteQuestion(i) {
      this.questions.splice(i, 1);
    },
    viewQuestion(i) {
      this.questionToView = this.questions[i];
      this.questionViewDialog = true;
    }
    // async save() {
    //   try {
    //     this.saving = true;
    //     const essayId = await this.$store.dispatch("questionBank/createEssay", {
    //       essay: this.essay,
    //       courseId: this.$courseId(),
    //       ownerId: this.$courseOwnerId()
    //     });
    //     const questionPromises = this.questions.map(q => {
    //       q.essay_id = essayId;
    //       return this.$store.dispatch("questionBank/create", {
    //         question: q,
    //         courseId: this.$courseId(),
    //         ownerId: this.$courseOwnerId()
    //       });
    //     });
    //     await Promise.all(questionPromises);
    //   } catch (e) {
    //     this.$root.$emit("alert", [undefined, e.message]);
    //   } finally {
    //     this.saving = false;
    //   }
    // }
  }
};
</script>

<style lang="scss">
.v-slide-group__prev {
  @media screen and (max-width: 360px) {
    display: none !important;
  }
}

.v-slide-group__next {
  @media screen and (max-width: 360px) {
    display: none !important;
  }
}
</style>
