<template>
  <v-container class="px-7 py-4">
    <v-form ref="form" v-model="allowSave">
      <v-row class="mt-3">
        <v-col cols="4">
          <v-select
            v-model="questionType"
            outlined
            v-bind="questionOptions.type"
          ></v-select>
        </v-col>
        <v-col v-if="showSubcategory" cols="4">
          <v-select
            v-model="questionCategory"
            outlined
            v-bind="questionOptions.category"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="questionDifficulty"
            outlined
            v-bind="questionOptions.difficulty"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <tip-tap-editor
            ref="editor"
            v-model="formData.content"
            class="rounded"
            label="Text"
            allow-list-insertion
            :allow-table-insertion="showButton.addTable"
          >
            <template #special-control="{ editor }">
              <v-btn
                v-if="showButton.addQuestion"
                outlined
                small
                color="primary"
                class="d-flex align-center"
                @click="addQuestion(editor)"
              >
                <v-icon>mdi-plus</v-icon>
                <span class="ml-2">Add quetion tag</span>
              </v-btn>
              <add-diagram-button
                v-if="showButton.addDiagram"
                @input="
                  v => {
                    addDiagram(v, editor);
                  }
                "
              ></add-diagram-button>
              <v-btn
                v-if="showButton.addCheckBox"
                outlined
                small
                color="primary"
                class="d-flex align-center"
                @click="addCheckBox(editor)"
              >
                <v-icon>mdi-plus</v-icon>
                <span class="ml-2">Add check box</span>
              </v-btn>
              <v-btn
                v-if="showButton.addMCQ"
                outlined
                small
                color="primary"
                class="d-flex align-center"
                @click="addMultipleChoice(editor)"
              >
                <v-icon>mdi-plus</v-icon>
                <span class="ml-2">Add check box</span>
              </v-btn>
              <v-btn
                v-if="showButton.addOptions"
                outlined
                small
                color="primary"
                class="ml-3 d-flex align-center"
                @click="addOptions(editor)"
              >
                <v-icon>mdi-plus</v-icon>
                <span class="ml-2">Add options</span>
              </v-btn>
            </template>
          </tip-tap-editor>
        </v-col>
        <v-col cols="12">
          <diagram-editor
            ref="diagram-editor"
            v-model="formData.diagram"
            class="my-4"
            :type="questionType"
          ></diagram-editor>
          <div
            v-for="(answer, index) in formData.answers"
            :key="index"
            class="d-flex align-center mb-3"
          >
            <span class="mr-2">Answer for question </span>
            <span style="max-width: 80px" class="mr-2"
              ><v-text-field
                v-model="answer.identifier"
                dense
                hide-details
                outlined
                :width="100"
              ></v-text-field></span
            >&nbsp;
            <span class="mr-4"> is </span>
            <span
              v-if="['fib', 'amcq'].includes(questionType)"
              style="width: 360px"
              class="mr-4"
              ><v-combobox
                v-model="answer.details"
                dense
                hide-details
                outlined
                multiple
                chips
                small-chips
                deletable-chips
              ></v-combobox
            ></span>
            <span v-else style="width: 360px" class="mr-4"
              ><v-text-field
                v-model="answer.details"
                dense
                hide-details
                outlined
              ></v-text-field
            ></span>
            <span class="mr-2">with order</span>
            <span style="max-width: 80px" class="mr-2">
              <v-text-field
                v-model="answer.order"
                dense
                hide-details
                outlined
                type="number"
                :width="100"
                :rules="[v => minValue(v, 0)]"
              ></v-text-field
            ></span>
            <v-btn icon depressed @click="removeAnswer(index)">
              <v-icon color="decline">delete</v-icon>
            </v-btn>
          </div>
          <v-btn
            v-if="showButton.addAnswer"
            outlined
            small
            color="primary"
            class="d-flex align-center"
            @click="addAnswer"
          >
            <v-icon>mdi-plus</v-icon>
            <span class="ml-2">Add answer</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import tipTapEditor from "@ecf/global/components/TipTapEditor.vue";
import AddDiagramButton from "./AddDiagramButton.vue";
import DiagramEditor from "./DiagramEditor.vue";
import { FieldValidations } from "/global/utils/validations";
import _ from "lodash";

export default {
  components: { tipTapEditor, AddDiagramButton, DiagramEditor },
  mixins: [FieldValidations],
  model: {
    prop: "outgoing",
    event: "update"
  },
  props: {
    outgoing: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      allowSave: undefined,
      questionType: undefined,
      questionCategory: undefined,
      questionDifficulty: undefined,
      formData: {
        content: undefined,
        diagram: {},
        answers: [],
        type: undefined,
        subType: undefined,
        difficulty: undefined
      },
      diagramURL: undefined
    };
  },
  computed: {
    questionOptions() {
      return {
        type: {
          label: this.$language ? "প্রশ্নের ধরণ" : "Question Type",
          items: [
            // {
            //   text: this.$language ? "বর্ণনামূলক প্রশ্ন" : "Descriptive",
            //   value: "desc",
            // },
            {
              text: this.$language ? "বক্তৃতামূলক" : "Speaking",
              value: "speak"
            },
            {
              text: this.$language ? "বহু নির্বাচনী" : "Multiple Choice",
              value: "amcq"
            },
            {
              text: this.$language ? "শূন্যস্থান পূরণ" : "Fill in the Blanks",
              value: "fib"
            },
            {
              text: this.$language ? "তথ্য মিল্করণ" : "Matching Information",
              value: "match"
            }
          ],
          rules: [v => this.required(v)]
        },
        category: {
          label: this.$language ? "প্রশ্নের প্রকার" : "Sub Type",
          items: this.subTypes(this.questionType),
          rules: [v => this.required(v)]
        },
        difficulty: {
          label: this.$language ? "কঠিনত্ব" : "Difficulty",
          items: [
            {
              text: this.$language ? "সহজ" : "Easy",
              value: 0
            },
            {
              text: this.$language ? "মাধ্যমিক" : "Medium",
              value: 1
            },
            {
              text: this.$language ? "কঠিন" : "Hard",
              value: 2
            }
          ],
          rules: [v => v === 0 || this.required(v)]
        }
      };
    },
    showButton() {
      return {
        addQuestion:
          (this.showSubcategory &&
            [
              "regular",
              "sentence_completion",
              "summary_completion",
              "note_completion",
              "table_completion",
              "short_answer",
              "match_feature",
              "match_sentence_ending",
              "match_heading"
            ].includes(this.questionCategory)) ||
          this.questionType === "speak",
        addCheckBox:
          this.showSubcategory && this.questionCategory === "match_information",
        addMCQ: this.questionType === "amcq",
        addDiagram:
          this.showSubcategory &&
          [
            "flow_chart_completion",
            "diagram_label_completion",
            "plan_labeling",
            "map_labeling",
            "diagram_labeling"
          ].includes(this.questionCategory),
        addOptions:
          this.questionType === "match" &&
          this.questionCategory &&
          !["match_feature", "match_information"].includes(
            this.questionCategory
          ),
        addTable: ["match_information", "table_completion"].includes(
          this.questionCategory
        ),
        addAnswer: this.showSubcategory || this.questionType === "amcq"
      };
    },
    showSubcategory() {
      return ["fib", "match"].includes(this.questionType);
    }
  },
  watch: {
    formData: {
      deep: true,
      handler(value) {
        this.$emit("update", value);
      }
    },
    questionType: {
      immediate: true,
      handler(value) {
        this.formData.type = value;
        this.formData.subType = undefined;
        this.formData.answers.splice(0, this.formData.answers.length);
      }
    },
    questionCategory: {
      immediate: true,
      handler(value) {
        this.formData.subType = value;
        this.formData.answers.splice(0, this.formData.answers.length);
      }
    },
    questionDifficulty: {
      immediate: true,
      handler(value) {
        this.formData.difficulty = value;
      }
    }
  },
  created() {
    this.formData = _.cloneDeep(this.outgoing);
  },
  methods: {
    subTypes(type) {
      switch (type) {
        case "fib":
          return [
            {
              text: this.$language ? "" : "Regular",
              value: "regular"
            },
            {
              text: this.$language ? "" : "Sentence Completion",
              value: "sentence_completion"
            },
            {
              text: this.$language ? "" : "Summary Completion",
              value: "summary_completion"
            },
            {
              text: this.$language ? "" : "Note Completion",
              value: "note_completion"
            },
            {
              text: this.$language ? "" : "table Completion",
              value: "table_completion"
            },
            {
              text: this.$language ? "" : "Flow chart Completion",
              value: "flow_chart_completion"
            },
            {
              text: this.$language ? "" : "Diagram label Completion",
              value: "diagram_label_completion"
            },
            {
              text: this.$language ? "" : "Short Answer",
              value: "short_answer"
            }
          ];
        case "match":
          return [
            {
              text: this.$language ? "" : "Match Information",
              value: "match_information"
            },
            {
              text: this.$language ? "" : "Match Heading",
              value: "match_heading"
            },
            {
              text: this.$language ? "" : "Match Feature",
              value: "match_feature"
            },
            {
              text: this.$language ? "" : "Match Sentence Ending",
              value: "match_sentence_ending"
            },
            {
              text: this.$language ? "" : "Plan labeling",
              value: "plan_labeling"
            },
            {
              text: this.$language ? "" : "Map labeling",
              value: "map_labeling"
            },
            {
              text: this.$language ? "" : "Diagram labeling",
              value: "diagram_labeling"
            }
          ];
        default:
          return [];
      }
    },
    addDiagram(file, editor) {
      this.$refs["diagram-editor"].assignFile(file);
      editor.commands.insertContent("$diagram");
    },
    addOptions(editor) {
      editor.commands.insertContent("$options");
    },
    addQuestion(editor) {
      editor.commands.insertContent("$question[]");
    },
    addCheckBox(editor) {
      editor.commands.insertContent("$check[][]");
    },
    addMultipleChoice(editor) {
      editor.commands.insertContent("$mcq[][][]");
    },
    addAnswer() {
      this.formData.answers.push({
        details: undefined,
        identifier: undefined,
        order: undefined
      });
    },
    removeAnswer(index) {
      this.formData.answers.splice(index, 1);
    }
  }
};
</script>
