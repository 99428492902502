<template>
  <div
    v-show="show"
    class="rounded border-2 border-solid border-primary"
    style="width: max-content"
  >
    <div class="pa-2 border-0 border-b border-solid border-primary">
      <v-btn
        v-if="!showIdInput"
        outlined
        small
        color="primary d-flex align-center"
        @click="showIdInput = true"
      >
        <v-icon>mdi-plus</v-icon>
        <span class="ml-2">Add quetion tag</span>
      </v-btn>
      <v-text-field
        v-else
        v-model="idValue"
        autofocus
        dense
        hide-details
        append-outer-icon="mdi-close"
        style="width: 180px; height: 28px; margin: 0"
        @keydown.enter="addQuestion"
        @click:append-outer="resetIdValues"
      ></v-text-field>
    </div>
    <div class="pa-2">
      <tag-diagram
        ref="diagram"
        v-bind="{
          type,
          modelAttr,
          questions: formData.questions,
          src: formData.file?.imageURL,
          movable: true,
          readonly: false,
        }"
        v-on="on"
      ></tag-diagram>
    </div>
  </div>
</template>

<script>
import TagDiagram from "./Tags/TagDiagram.vue";
import _ from "lodash";

export default {
  props: {
    outgoing: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
    },
  },
  model: {
    prop: "outgoing",
    event: "update",
  },
  components: { TagDiagram },
  created() {
    this.formData = _.cloneDeep(this.outgoing);
  },
  data() {
    return {
      show: false,
      showIdInput: false,
      idValue: undefined,
      modelAttr: {},
      formData: {
        file: undefined,
        questions: undefined,
      },
      on: {
        "update-question": this.updateQuestion,
      },
    };
  },
  watch: {
    "outgoing.file.imageURL": {
      immediate: true,
      handler(value) {
        this.show = value?.length > 0;
      },
    },
    formData: {
      immediate: true,
      deep: true,
      handler(value) {
        this.$emit("update", value);
      },
    },
  },
  methods: {
    addQuestion() {
      this.$refs.diagram.add(this.idValue);
      this.resetIdValues();
    },
    resetIdValues() {
      this.showIdInput = false;
      this.idValue = undefined;
    },
    assignFile(payload) {
      this.formData.file = payload;
    },
    updateQuestion(payload) {
      this.formData.questions = payload;
    },
  },
};
</script>