<template>
  <div class="rounded">
    <div
      class="pa-3 primary white--text d-flex align-center justify-space-between"
    >
      <span class="xl:text-xl">Preview Question</span>
      <v-btn icon @click="$emit('close')">
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="bg-white pa-4 grid grid-cols-2 grid-gap-3">
      <essay-viewer :reactive="true" :essay-content="essay"></essay-viewer>
      <question-group-viewer
        class="pa-2"
        v-bind="$props"
        :readonly="false"
      ></question-group-viewer>
    </div>
  </div>
</template>

<script>
import EssayViewer from "./EssayViewer.vue";
import QuestionGroupViewer from "./QuestionGroupViewer.vue";

export default {
  components: { EssayViewer, QuestionGroupViewer },
  props: ["essay", "content", "questions", "answers"]
};
</script>

<style lang="scss" scoped></style>
