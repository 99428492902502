var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-7 py-4" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.allowSave,
            callback: function ($$v) {
              _vm.allowSave = $$v
            },
            expression: "allowSave",
          },
        },
        [
          _c(
            "v-row",
            { staticClass: "mt-3" },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-select",
                    _vm._b(
                      {
                        attrs: { outlined: "" },
                        model: {
                          value: _vm.questionType,
                          callback: function ($$v) {
                            _vm.questionType = $$v
                          },
                          expression: "questionType",
                        },
                      },
                      "v-select",
                      _vm.questionOptions.type,
                      false
                    )
                  ),
                ],
                1
              ),
              _vm.showSubcategory
                ? _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-select",
                        _vm._b(
                          {
                            attrs: { outlined: "" },
                            model: {
                              value: _vm.questionCategory,
                              callback: function ($$v) {
                                _vm.questionCategory = $$v
                              },
                              expression: "questionCategory",
                            },
                          },
                          "v-select",
                          _vm.questionOptions.category,
                          false
                        )
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-select",
                    _vm._b(
                      {
                        attrs: { outlined: "" },
                        model: {
                          value: _vm.questionDifficulty,
                          callback: function ($$v) {
                            _vm.questionDifficulty = $$v
                          },
                          expression: "questionDifficulty",
                        },
                      },
                      "v-select",
                      _vm.questionOptions.difficulty,
                      false
                    )
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("tip-tap-editor", {
                    ref: "editor",
                    staticClass: "rounded",
                    attrs: {
                      label: "Text",
                      "allow-list-insertion": "",
                      "allow-table-insertion": _vm.showButton.addTable,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "special-control",
                        fn: function ({ editor }) {
                          return [
                            _vm.showButton.addQuestion
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "d-flex align-center",
                                    attrs: {
                                      outlined: "",
                                      small: "",
                                      color: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addQuestion(editor)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [_vm._v("mdi-plus")]),
                                    _c("span", { staticClass: "ml-2" }, [
                                      _vm._v("Add quetion tag"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.showButton.addDiagram
                              ? _c("add-diagram-button", {
                                  on: {
                                    input: (v) => {
                                      _vm.addDiagram(v, editor)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.showButton.addCheckBox
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "d-flex align-center",
                                    attrs: {
                                      outlined: "",
                                      small: "",
                                      color: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addCheckBox(editor)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [_vm._v("mdi-plus")]),
                                    _c("span", { staticClass: "ml-2" }, [
                                      _vm._v("Add check box"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.showButton.addMCQ
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "d-flex align-center",
                                    attrs: {
                                      outlined: "",
                                      small: "",
                                      color: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addMultipleChoice(editor)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [_vm._v("mdi-plus")]),
                                    _c("span", { staticClass: "ml-2" }, [
                                      _vm._v("Add check box"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.showButton.addOptions
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-3 d-flex align-center",
                                    attrs: {
                                      outlined: "",
                                      small: "",
                                      color: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addOptions(editor)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [_vm._v("mdi-plus")]),
                                    _c("span", { staticClass: "ml-2" }, [
                                      _vm._v("Add options"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.formData.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "content", $$v)
                      },
                      expression: "formData.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("diagram-editor", {
                    ref: "diagram-editor",
                    staticClass: "my-4",
                    attrs: { type: _vm.questionType },
                    model: {
                      value: _vm.formData.diagram,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "diagram", $$v)
                      },
                      expression: "formData.diagram",
                    },
                  }),
                  _vm._l(_vm.formData.answers, function (answer, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "d-flex align-center mb-3" },
                      [
                        _c("span", { staticClass: "mr-2" }, [
                          _vm._v("Answer for question "),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "mr-2",
                            staticStyle: { "max-width": "80px" },
                          },
                          [
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                outlined: "",
                                width: 100,
                              },
                              model: {
                                value: answer.identifier,
                                callback: function ($$v) {
                                  _vm.$set(answer, "identifier", $$v)
                                },
                                expression: "answer.identifier",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v("  "),
                        _c("span", { staticClass: "mr-4" }, [_vm._v(" is ")]),
                        ["fib", "amcq"].includes(_vm.questionType)
                          ? _c(
                              "span",
                              {
                                staticClass: "mr-4",
                                staticStyle: { width: "360px" },
                              },
                              [
                                _c("v-combobox", {
                                  attrs: {
                                    dense: "",
                                    "hide-details": "",
                                    outlined: "",
                                    multiple: "",
                                    chips: "",
                                    "small-chips": "",
                                    "deletable-chips": "",
                                  },
                                  model: {
                                    value: answer.details,
                                    callback: function ($$v) {
                                      _vm.$set(answer, "details", $$v)
                                    },
                                    expression: "answer.details",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "mr-4",
                                staticStyle: { width: "360px" },
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    "hide-details": "",
                                    outlined: "",
                                  },
                                  model: {
                                    value: answer.details,
                                    callback: function ($$v) {
                                      _vm.$set(answer, "details", $$v)
                                    },
                                    expression: "answer.details",
                                  },
                                }),
                              ],
                              1
                            ),
                        _c("span", { staticClass: "mr-2" }, [
                          _vm._v("with order"),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "mr-2",
                            staticStyle: { "max-width": "80px" },
                          },
                          [
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                outlined: "",
                                type: "number",
                                width: 100,
                                rules: [(v) => _vm.minValue(v, 0)],
                              },
                              model: {
                                value: answer.order,
                                callback: function ($$v) {
                                  _vm.$set(answer, "order", $$v)
                                },
                                expression: "answer.order",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", depressed: "" },
                            on: {
                              click: function ($event) {
                                return _vm.removeAnswer(index)
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "decline" } }, [
                              _vm._v("delete"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _vm.showButton.addAnswer
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "d-flex align-center",
                          attrs: { outlined: "", small: "", color: "primary" },
                          on: { click: _vm.addAnswer },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-plus")]),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v("Add answer"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }