var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { class: !_vm.aboveHalfFHD ? `pa-0` : `` },
    [
      _c("page-title", {
        staticClass: "mt-5",
        attrs: {
          "page-title": !_vm.$language
            ? "Create Group Question"
            : "গ্রুপ প্রশ্ন তৈরি করুন",
        },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-2",
                    attrs: { color: "black", icon: "", large: "" },
                    on: {
                      click: () => {
                        _vm.$router.push({
                          name: "question-list",
                        })
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("arrow_back")])],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.aboveHalfFHD
        ? _c("group-question-create", {
            on: { saved: _vm.goToList, cancel: _vm.goToList },
          })
        : _c(
            "div",
            {
              staticClass: "center-content",
              staticStyle: { height: "60vh", color: "grey" },
            },
            [_vm._v(" Switch to a bigger screen to use this feature. ")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }