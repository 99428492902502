<template>
  <div class="py-4 px-7">
    <v-row class="mt-3">
      <v-col cols="5"></v-col>
      <v-col cols="7">
        <slot name="top-right"></slot>
      </v-col>
    </v-row>
    <v-row
      class="pa-4 border border-solid rounded d-flex flex-column"
      style="position: relative"
    >
      <span
        class="px-3 white black--text"
        style="position: absolute; top: -0.75rem"
        >Select Question</span
      >
      <div style="max-height: 480px;" class="d-flex flex-column">
        <div
          class="pb-4 pt-2 grid grid-cols-9 grid-gap-4 border-0 border-b border-solid border-default"
        >
          <div class="grid-col-span-5">
            <v-text-field
              v-model="searchKey"
              flat
              outlined
              dense
              hide-details
              prepend-inner-icon="search"
              placeholder="Search Questions"
              @keydown.enter="searchWrapped()"
            ></v-text-field>
          </div>
          <div class="grid-col-span-3">
            <v-autocomplete
              v-model="searchTopics"
              :items="topics"
              item-value="id"
              flat
              outlined
              dense
              multiple
              hide-details
              placeholder="Select Topic"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip
                  v-if="index === 0"
                  label
                  style="background: rgba(0, 153, 220, 0.4)"
                >
                  <span v-if="item.length < 15">{{ item }}</span>
                  <span v-else
                    >{{ item.text.htmlToText().substring(0, 15) }}...</span
                  >
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ searchTopics.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </div>
          <div class="grid-col-span-1">
            <v-btn
              depressed
              block
              color="primary"
              height="40"
              class="d-flex justify-space-around white--text"
              @click="searchWrapped()"
            >
              <span>Search</span>
            </v-btn>
          </div>
        </div>
        <div class="flex-fill overflow-y-auto">
          <v-card
            v-if="loading['status']"
            flat
            class="pa-6 d-flex align-center justify-center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-card>
          <v-card
            v-for="(essay, index) in essays"
            v-else
            :key="index"
            flat
            class="pa-6 d-flex align-center border-0 border-b border-solid border-default"
          >
            <v-simple-checkbox
              class="mr-3"
              color="primary"
              :value="selected === essay.id"
              @click="selectEssay(essay)"
            ></v-simple-checkbox>
            <essay-viewer
              style="max-width: 960px"
              :essay-content="essay"
              :outlined="false"
              class="pa-0"
            ></essay-viewer>
          </v-card>
        </div>
        <div class="pa-3">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import { apiCallUtils } from "/global/utils/mixins";
import EssayViewer from "#ecf/question-bank/components/GroupQuestion/EssayViewer.vue";

export default {
  components: { EssayViewer },
  mixins: [apiCallUtils],
  data() {
    return {
      searchKey: undefined,
      searchTopics: [],
      essays: [],
      total: 0,
      page: 1,
      itemsPerPage: 10,
      loading: {},
      selected: undefined
    };
  },
  computed: {
    topics() {
      return this.$store.state.questionBank.topics.topics;
    },
    ownerParams() {
      return {
        owner_id: this.$store.state.user.user.user_id,
        course_id: this.$ielts_course_id
      };
    },
    pageCount() {
      return Math.ceil(this.total / this.itemsPerPage);
    }
  },
  watch: {
    page: {
      immediate: true,
      async handler() {
        await this.searchWrapped();
      }
    }
  },
  async created() {
    await this.waitingCall(this.fetchLinkable, this.loading, "status");
  },
  methods: {
    async fetchLinkable() {
      await this.$store.dispatch("questionBank/topics/list", this.ownerParams);
    },
    async searchWrapped() {
      await this.waitingCall(this.search, this.loading, "status");
    },
    async search() {
      let items_per_page = 10;
      let offset = (this.page - 1) * 10;
      let search_key = this.searchKey;
      let topics = this.searchTopics;

      let resp = await this.$store.dispatch("questionBank/searchLinkable", {
        ...this.ownerParams,
        offset,
        items_per_page,
        search_key,
        topics
      });

      this.essays = resp.essays;
      this.total = resp.total;
    },
    selectEssay(essay) {
      if (this.selected === essay.id) this.selected = undefined;
      else this.selected = essay.id;
      this.$emit("select", {
        id: this.selected,
        body: {
          text: this.selected ? essay.item_text : "",
          files: this.selected ? essay.files : {}
        }
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
