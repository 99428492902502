var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rounded" }, [
    _c(
      "div",
      {
        staticClass:
          "pa-3 primary white--text d-flex align-center justify-space-between",
      },
      [
        _c("span", { staticClass: "xl:text-xl" }, [_vm._v("Preview Question")]),
        _c(
          "v-btn",
          {
            attrs: { icon: "" },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_c("v-icon", { attrs: { color: "white" } }, [_vm._v("mdi-close")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bg-white pa-4 grid grid-cols-2 grid-gap-3" },
      [
        _c("essay-viewer", {
          attrs: { reactive: true, "essay-content": _vm.essay },
        }),
        _c(
          "question-group-viewer",
          _vm._b(
            { staticClass: "pa-2", attrs: { readonly: false } },
            "question-group-viewer",
            _vm.$props,
            false
          )
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }