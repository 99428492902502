var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "d-flex align-center",
      attrs: { outlined: "", small: "", color: "primary" },
      on: {
        click: function ($event) {
          return _vm.$refs.file.click()
        },
      },
    },
    [
      _c("v-icon", [_vm._v("mdi-plus")]),
      _c("span", { staticClass: "ml-2" }, [_vm._v("Add Diagram")]),
      _c("input", {
        ref: "file",
        staticStyle: { display: "none" },
        attrs: {
          type: "file",
          name: "filename",
          accept: ".gif, .jpg, .jpeg, .png, .svg",
        },
        on: { change: _vm.onFileChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }