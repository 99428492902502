<template>
  <v-container :class="mobileScreen ? `pa-0` : ``">
    <div class="py-3" style="max-width: 1100px">
      <submission-alert-message
        ref="banner"
        v-model="showAlertCondition"
        should-scroll-to-top
        :message="badExamFormMessage[$language]"
      ></submission-alert-message>
    </div>
    <v-card class="m-light-card">
      <v-row no-gutters>
        <v-col cols="7">
          <v-tabs v-model="currentStep">
            <v-tab>
              {{ !$language ? "1. Essay" : "১. রচনা" }}
            </v-tab>
            <v-tab>
              {{
                !$language ? "2. Questions & Answers" : "২. প্রশ্ন এবং উত্তর"
              }}
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="5" class="pr-7 d-flex justify-end align-center">
          <v-btn
            v-show="advanced"
            outlined
            color="primary"
            class="mr-4"
            @click="preview()"
            >Preview
          </v-btn>
          <v-switch v-model="advanced" dense label="Advanced"></v-switch>
        </v-col>
      </v-row>

      <v-tabs-items v-model="currentStep">
        <v-tab-item>
          <essay-form
            v-if="!modeLinked"
            ref="essayForm"
            v-model="essay"
            :course-id="this.$courseId()"
            :is-valid.sync="essayok"
            @essay-update="updateEssayContent"
          >
            <template #top-right>
              <div class="d-flex justify-end align-center">
                <v-switch
                  v-if="advanced"
                  v-model="modeLinked"
                  dense
                  hide-details
                  label="Link Group Question"
                  class="mt-0"
                ></v-switch>
              </div>
            </template>
          </essay-form>
          <linkable-group-question-list
            v-else
            @select="
              v => {
                linkingId = v.id;
                linkingBody = v.body;
              }
            "
          >
            <template #top-right>
              <div class="d-flex justify-end align-center">
                <v-switch
                  v-if="advanced"
                  v-model="modeLinked"
                  dense
                  hide-details
                  label="Link Group Question"
                  class="mt-0"
                ></v-switch>
              </div>
            </template>
          </linkable-group-question-list>
          <tab-navigation
            :language="$language"
            previous-disabled
            @next="nextStep"
            @previous="previousStep"
          ></tab-navigation>
        </v-tab-item>

        <v-tab-item>
          <div v-if="advanced">
            <advanced-question-answer-form
              v-model="questionGroup"
            ></advanced-question-answer-form>
            <tab-navigation
              :language="$language"
              @next="nextStep"
              @previous="previousStep"
            >
              <template v-slot:right>
                <m-rounded-button
                  outlined
                  color="red accent-3"
                  data-qa="cancel-button"
                  @click="cancel"
                >
                  <m-icon left>cancel</m-icon>
                  {{ !$language ? "Cancel" : "বাতিল করুন" }}
                </m-rounded-button>
                <m-rounded-button
                  :loading="creating"
                  class="ml-2"
                  @click="saveAdvanced"
                >
                  <m-icon left>save_alt</m-icon>
                  {{ !$language ? "Save" : "সেভ করুন" }}
                </m-rounded-button>
              </template>
            </tab-navigation>
          </div>
          <div v-else>
            <question-set-form
              ref="questionSetForm"
              v-model="questions"
              :is-valid.sync="qaok"
            ></question-set-form>
            <tab-navigation
              :language="$language"
              @next="nextStep"
              @previous="previousStep"
            >
              <template v-slot:right>
                <m-rounded-button
                  outlined
                  color="red accent-3"
                  data-qa="cancel-button"
                  @click="cancel"
                >
                  <m-icon left>cancel</m-icon>
                  {{ !$language ? "Cancel" : "বাতিল করুন" }}
                </m-rounded-button>
                <m-rounded-button
                  :loading="creating"
                  class="ml-2"
                  @click="save"
                >
                  <m-icon left>save_alt</m-icon>
                  {{ !$language ? "Save" : "সেভ করুন" }}
                </m-rounded-button>
              </template>
            </tab-navigation>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog v-model="showPreview">
      <question-previewer
        v-bind="previewProps"
        @close="showPreview = false"
      ></question-previewer>
    </v-dialog>
  </v-container>
</template>

<script>
import EssayForm from "#ecf/question-bank/components/GroupQuestion/FormEssay";
import QuestionSetForm from "#ecf/question-bank/components/GroupQuestion/FormQuestionSet";
import AdvancedQuestionAnswerForm from "#ecf/question-bank/components/GroupQuestion/AdvancedQuestionAnswerForm.vue";
import TabNavigation from "~ecf/components/TabNavigation";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage";
import QuestionPreviewer from "./QuestionPreviewer.vue";
import _ from "lodash";
import LinkableGroupQuestionList from "#ecf/question-bank/components/GroupQuestion/LinkableGroupQuestionList.vue";

const QuestionTag = (type, subType) => {
  if (type === "fib") return "fib";
  else if (type === "speak") return "speak";
  else if (type === "match" && subType === "match_feature") return "select";
  else return "match";
};

export default {
  name: "GroupQuestionCreate",
  components: {
    LinkableGroupQuestionList,
    AdvancedQuestionAnswerForm,
    QuestionSetForm,
    EssayForm,
    TabNavigation,
    SubmissionAlertMessage,
    QuestionPreviewer
  },
  data() {
    return {
      difficulty: ["Easy", "Medium", "Hard"],
      essay: {
        text: undefined,
        image: { imageURL: null, file: null }
      },
      essayContentWithMedia: undefined,
      questions: [],
      questionGroup: {
        content: undefined,
        diagram: {
          file: undefined,
          questions: []
        },
        answers: [],
        type: undefined,
        subType: undefined,
        difficulty: undefined,
        allowPreview: undefined
      },
      currentStep: 0,
      creating: false,
      essayok: true,
      qaok: true,
      showAlertCondition: false,
      advanced: false,
      showPreview: false,
      previewProps: {
        essay: undefined,
        content: undefined,
        questions: undefined,
        answers: undefined
      },
      modeLinked: undefined,
      linkingId: undefined,
      linkingBody: undefined
    };
  },
  computed: {
    mobileScreen() {
      return this.$vuetify.breakpoint.width <= 360;
    },
    topics() {
      return this.$store.state.questionBank.topics.topics;
    },
    allowSaveCondition() {
      return this.essayok && this.qaok;
    },
    badExamFormMessage() {
      return {
        false: "You must fill out all the mandatory fields",
        true: "সকল প্রয়োজনীয় তথ্য পূরণ করুন"
      };
    },
    diagramFile() {
      let file = this.questionGroup.diagram?.file?.file;
      let url = this.questionGroup.diagram?.file?.imageURL;
      if (!file) return {};
      return {
        key: url.split("/").reverse()[0],
        value: {
          file,
          type: "image",
          name: file.name,
          format: file.name.split(".").pop()
        }
      };
    }
  },
  inject: ["$courseOwnerId", "$courseId"],
  created() {
    this.$store.dispatch("questionBank/topics/list", {
      owner_id: this.$courseOwnerId(),
      course_id: this.$courseId()
    });
  },
  methods: {
    updateEssayContent(value) {
      this.essayContentWithMedia = value;
    },
    nextStep() {
      if (this.currentStep !== 1) {
        this.currentStep++;
      }
    },
    previousStep() {
      if (this.currentStep !== 0) {
        this.currentStep--;
      }
    },
    async save() {
      if (!this.allowSaveCondition) {
        this.$refs.essayForm.validate();
        this.$refs.questionSetForm.validate();
        if (!this.showAlertCondition) this.showAlertCondition = true;
        else {
          await this.$refs.banner.flash();
        }
        return;
      }
      if (this.creating) return;
      try {
        this.essay.text = this.essayContentWithMedia.valueWithMediaBlobUrls;
        this.essay.files = this.essayContentWithMedia.insertedFiles;
        this.creating = true;
        const essayId = await this.$store.dispatch("questionBank/createEssay", {
          essay: this.essay,
          courseId: this.$courseId(),
          ownerId: this.$courseOwnerId()
        });
        const questionPromises = this.questions.map(q => {
          q.questionDetails.text = q.questionDetails.text || undefined;
          if (q.questionDetails.questionType === "desc") {
            delete q.answers;
          } else {
            delete q.answerPreference;
          }
          q.essay_id = essayId;
          q.questionDetails.topics = this.essay.topics ?? [];
          return this.$store.dispatch("questionBank/create", {
            question: q,
            courseId: this.$courseId(),
            ownerId: this.$courseOwnerId()
          });
        });
        await Promise.all(questionPromises);
        this.$emit("saved");
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.creating = false;
      }
    },
    async cancel() {
      this.$emit("cancel");
    },
    constructOutgoingElements(preview) {
      let advancedAnswers = this.questionGroup.answers;

      let advancedQuestions = [];

      let qType = this.questionGroup.type;
      let qSubType = this.questionGroup.subType;

      let reId = /[0-9a-zA-Z]+/;
      let reLabel = /[0-9a-zA-Z£!@#$%^&()_+\-\\=;':|,.?/ ]+/;

      let reQues = /\$question\[[0-9a-zA-Z]+\]/;
      let reOptions = /\$options/;
      let reCheckBox = /\$check\[[0-9a-zA-Z]+\]\[[0-9a-zA-Z]+\]/;
      let reDiagram = /\$diagram/;
      let reMultiChoice = /\$mcq\[[0-9a-zA-Z]+\]\[[0-9a-zA-Z]+\]\[[0-9a-zA-Z£!@#$%^&()_+\-\\=;':|,.?/ ]+\]/;

      let questionGroupText = _.clone(this.questionGroup.content);
      let match = reQues.exec(questionGroupText);

      // search and replace question markers with tag items
      while (match) {
        let id = match[0].split("[")[1].match(reId)[0];
        questionGroupText = questionGroupText.replace(
          reQues,
          `<tag-${QuestionTag(
            qType,
            qSubType
          )} identifier="${id}" v-bind="attrs" v-on="on"></tag-${QuestionTag(
            qType,
            qSubType
          )}>`
        );
        advancedQuestions.push({
          identifier: id
        });
        match = reQues.exec(questionGroupText);
      }

      // search and replace options markers with tag items
      let tegChip = `<tag-chips ref="opt" v-bind="attrs" v-on="on"></tag-chips>`;
      if (
        qType === "match" &&
        !["match_feature", "match_information"].includes(qSubType)
      ) {
        match = reOptions.exec(questionGroupText);
        if (match) {
          questionGroupText = questionGroupText.replace(reOptions, tegChip);
        } else {
          questionGroupText += tegChip;
        }
      }

      // modifies text item for diagram question
      let src = () => {
        return preview
          ? this.questionGroup.diagram?.file?.imageURL
          : !this.diagramFile.key
          ? ""
          : !this.diagramFile.value.format
          ? ""
          : `${this.diagramFile.key}.${this.diagramFile.value.format}`;
      };
      let tagDiagram =
        `<tag-diagram type="${qType}" src="${src()}" v-bind="attrs" v-on="on"></tag-diagram>` ||
        "";
      match = reDiagram.exec(questionGroupText);
      if (match) {
        questionGroupText = questionGroupText.replace(reDiagram, tagDiagram);
        advancedQuestions = _.cloneDeep(this.questionGroup.diagram.questions);
      }

      // modifies text item for checkbox question
      match = reCheckBox.exec(questionGroupText);
      let questionSet = new Set();
      while (match) {
        let parts = match[0].split("[");
        let id = parts[1].match(reId)[0];
        let index = parts[2].match(reId)[0];

        questionGroupText = questionGroupText.replace(
          reCheckBox,
          `<tag-check identifier="${id}" value="${index}" v-bind="attrs" v-on="on"></tag-check>`
        );

        questionSet.add(id);
        match = reCheckBox.exec(questionGroupText);
      }

      if (qSubType === "match_information") {
        let dom = new DOMParser().parseFromString(
          questionGroupText,
          "text/html"
        );
        let tables = dom.querySelectorAll("table");
        tables.forEach(table => {
          table.classList.add("match-table");
        });
        questionGroupText = dom.body.innerHTML;
        advancedQuestions = [...questionSet].map(item => ({
          identifier: item
        }));
      }

      // modifies text item for mcq question
      match = reMultiChoice.exec(questionGroupText);
      let questionSetMultiChoice = new Set();
      while (match) {
        let parts = match[0].split("[");
        let id = parts[1].match(reId)[0];
        let index = parts[2].match(reId)[0];
        let label = parts[3].match(reLabel)[0];

        questionGroupText = questionGroupText.replace(
          reMultiChoice,
          `<tag-mcq identifier="${id}" value="${index}" label="${label}" v-bind="attrs" v-on="on"></tag-mcq>`
        );

        questionSetMultiChoice.add(id);
        match = reMultiChoice.exec(questionGroupText);
      }

      if (qType === "amcq") {
        let dom = new DOMParser().parseFromString(
          questionGroupText,
          "text/html"
        );
        questionGroupText = dom.body.innerHTML;
        advancedQuestions = [...questionSetMultiChoice].map(item => ({
          identifier: item
        }));
      }

      return [questionGroupText, advancedQuestions, advancedAnswers];
    },
    preview() {
      let [
        questionGroupText,
        advancedQuestions,
        advancedAnswers
      ] = this.constructOutgoingElements(true);

      if (
        !this.confirmQuestionAndAnswerMatch(advancedQuestions, advancedAnswers)
      )
        return;

      if (
        !this.checkAndAppendOrderToQuestions(advancedQuestions, advancedAnswers)
      )
        return;

      this.previewProps.essay = this.modeLinked
        ? this.linkingBody
        : {
            text: this.essayContentWithMedia?.valueWithMediaBlobUrls || "",
            files: this.essayContentWithMedia?.insertedFiles
          };
      this.previewProps.content = questionGroupText;
      this.previewProps.questions = advancedQuestions;
      this.previewProps.answers = advancedAnswers;

      this.showPreview = true;
    },
    async saveAdvanced() {
      if (this.creating) return;
      try {
        this.creating = true;

        let [
          questionGroupText,
          advancedQuestions,
          advancedAnswers
        ] = this.constructOutgoingElements(false);

        // check if all question were provided answers and answers do not reference absent questions
        if (
          !this.confirmQuestionAndAnswerMatch(
            advancedQuestions,
            advancedAnswers
          )
        )
          return;

        if (
          !this.checkAndAppendOrderToQuestions(
            advancedQuestions,
            advancedAnswers
          )
        )
          return;

        const essayId = await this.saveEssay();

        await this.$store.dispatch("questionBank/createQuestionInfo", {
          questionGroup: {
            text: questionGroupText,
            files: {
              [this.diagramFile.key]: this.diagramFile.value
            }
          },
          questions: advancedQuestions,
          answers: advancedAnswers,
          difficulty: this.questionGroup.difficulty,
          type: this.questionGroup.type,
          subType: this.questionGroup.subType,
          courseId: this.$courseId(),
          ownerId: this.$courseOwnerId(),
          essayId
        });
        this.$emit("saved");
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.creating = false;
      }
    },
    async saveEssay() {
      if (![0, 1, 2].includes(this.questionGroup?.difficulty))
        throw new Error("Difficulty not selected");
      if (this.modeLinked) {
        if (this.linkingId === undefined)
          throw new Error("Parent Question not selected");
        return this.linkingId;
      } else {
        if (!this.essayContentWithMedia) throw new Error("Must have preamble");
        this.essay.text = this.essayContentWithMedia?.valueWithMediaBlobUrls;
        this.essay.files = {
          ...this.essayContentWithMedia?.insertedFiles
        };

        return await this.$store.dispatch("questionBank/createEssay", {
          essay: this.essay,
          courseId: this.$courseId(),
          ownerId: this.$courseOwnerId()
        });
      }
    },
    confirmQuestionAndAnswerMatch(questions, answers) {
      if (this.questionGroup.type === "speak") return true;
      let qids = questions.reduce((acc, curr) => {
        acc[curr.identifier] = true;
        return acc;
      }, {});
      let ansIds = answers.reduce((acc, curr) => {
        let id = curr.identifier;
        if (id) acc[id] = true;
        return acc;
      }, {});

      let missingAnswers = [];
      for (let key in qids) {
        if (!(key in ansIds)) {
          missingAnswers.push(key);
        }
      }
      if (missingAnswers.length > 0) {
        let mult = missingAnswers.length > 1;
        this.$root.$emit("alert", [
          undefined,
          `Question${mult ? "s" : ""} (${missingAnswers.join(", ")}) ${
            mult ? "were" : "was"
          } not proveided answers`
        ]);
        return false;
      }

      let missingQuestions = [];
      for (let key in ansIds) {
        if (!(key in qids)) {
          missingQuestions.push(key);
        }
      }
      if (missingQuestions.length > 0) {
        let mult = missingQuestions.length > 1;
        this.$root.$emit("alert", [
          undefined,
          `Question${mult ? "s" : ""} (${missingQuestions.join(", ")}) ${
            mult ? "are" : "is"
          } not present`
        ]);
        return false;
      }

      return true;
    },
    checkAndAppendOrderToQuestions(questions, answers) {
      let id_v_q = questions.reduce((acc, curr) => {
        acc[curr.identifier] = curr;
        return acc;
      }, {});
      let orderArray = answers.reduce((acc, curr) => {
        acc.push(Number(curr.order));
        return acc;
      }, []);
      let orderSet = new Set(orderArray);
      if (orderArray.length !== orderSet.size) {
        this.$root.$emit("alert", [undefined, `Has duplicate order values`]);
        return false;
      }
      for (let i = 0; i < orderSet.size; i++) {
        if (!orderSet.has(i)) {
          this.$root.$emit("alert", [
            undefined,
            `Has missing order values or doesn't start at 0`
          ]);
          return false;
        }
      }
      answers.forEach(answer => {
        id_v_q[answer.identifier]["collection_order"] = Number(answer.order);
      });
      return true;
    }
  }
};
</script>

<style></style>
