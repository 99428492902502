<template>
  <v-btn
    outlined
    small
    color="primary"
    class="d-flex align-center"
    @click="$refs.file.click()"
  >
    <v-icon>mdi-plus</v-icon>
    <span class="ml-2">Add Diagram</span>
    <input
      ref="file"
      type="file"
      name="filename"
      accept=".gif, .jpg, .jpeg, .png, .svg"
      style="display: none"
      @change="onFileChange"
    />
  </v-btn>
</template>

<script>
export default {
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onFileChange(event) {
      if (event.target.files.length > 0) {
        let file = event.target.files[0];
        let imageURL = URL.createObjectURL(file);
        this.$emit("input", { file, imageURL });
      } else {
        this.$emit("input", { file: null, imageURL: null });
      }
    },
  },
};
</script>