var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "rounded border-2 border-solid border-primary",
      staticStyle: { width: "max-content" },
    },
    [
      _c(
        "div",
        { staticClass: "pa-2 border-0 border-b border-solid border-primary" },
        [
          !_vm.showIdInput
            ? _c(
                "v-btn",
                {
                  attrs: {
                    outlined: "",
                    small: "",
                    color: "primary d-flex align-center",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showIdInput = true
                    },
                  },
                },
                [
                  _c("v-icon", [_vm._v("mdi-plus")]),
                  _c("span", { staticClass: "ml-2" }, [
                    _vm._v("Add quetion tag"),
                  ]),
                ],
                1
              )
            : _c("v-text-field", {
                staticStyle: { width: "180px", height: "28px", margin: "0" },
                attrs: {
                  autofocus: "",
                  dense: "",
                  "hide-details": "",
                  "append-outer-icon": "mdi-close",
                },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.addQuestion.apply(null, arguments)
                  },
                  "click:append-outer": _vm.resetIdValues,
                },
                model: {
                  value: _vm.idValue,
                  callback: function ($$v) {
                    _vm.idValue = $$v
                  },
                  expression: "idValue",
                },
              }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pa-2" },
        [
          _c(
            "tag-diagram",
            _vm._g(
              _vm._b(
                { ref: "diagram" },
                "tag-diagram",
                {
                  type: _vm.type,
                  modelAttr: _vm.modelAttr,
                  questions: _vm.formData.questions,
                  src: _vm.formData.file?.imageURL,
                  movable: true,
                  readonly: false,
                },
                false
              ),
              _vm.on
            )
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }