var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "py-4 px-7" },
    [
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c("v-col", { attrs: { cols: "5" } }),
          _c("v-col", { attrs: { cols: "7" } }, [_vm._t("top-right")], 2),
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "pa-4 border border-solid rounded d-flex flex-column",
          staticStyle: { position: "relative" },
        },
        [
          _c(
            "span",
            {
              staticClass: "px-3 white black--text",
              staticStyle: { position: "absolute", top: "-0.75rem" },
            },
            [_vm._v("Select Question")]
          ),
          _c(
            "div",
            {
              staticClass: "d-flex flex-column",
              staticStyle: { "max-height": "480px" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "pb-4 pt-2 grid grid-cols-9 grid-gap-4 border-0 border-b border-solid border-default",
                },
                [
                  _c(
                    "div",
                    { staticClass: "grid-col-span-5" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          flat: "",
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          "prepend-inner-icon": "search",
                          placeholder: "Search Questions",
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.searchWrapped()
                          },
                        },
                        model: {
                          value: _vm.searchKey,
                          callback: function ($$v) {
                            _vm.searchKey = $$v
                          },
                          expression: "searchKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "grid-col-span-3" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.topics,
                          "item-value": "id",
                          flat: "",
                          outlined: "",
                          dense: "",
                          multiple: "",
                          "hide-details": "",
                          placeholder: "Select Topic",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ item, index }) {
                              return [
                                index === 0
                                  ? _c(
                                      "v-chip",
                                      {
                                        staticStyle: {
                                          background: "rgba(0, 153, 220, 0.4)",
                                        },
                                        attrs: { label: "" },
                                      },
                                      [
                                        item.length < 15
                                          ? _c("span", [_vm._v(_vm._s(item))])
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.text
                                                    .htmlToText()
                                                    .substring(0, 15)
                                                ) + "..."
                                              ),
                                            ]),
                                      ]
                                    )
                                  : _vm._e(),
                                index === 1
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "grey--text text-caption",
                                      },
                                      [
                                        _vm._v(
                                          " (+" +
                                            _vm._s(
                                              _vm.searchTopics.length - 1
                                            ) +
                                            " others) "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.searchTopics,
                          callback: function ($$v) {
                            _vm.searchTopics = $$v
                          },
                          expression: "searchTopics",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "grid-col-span-1" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "d-flex justify-space-around white--text",
                          attrs: {
                            depressed: "",
                            block: "",
                            color: "primary",
                            height: "40",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.searchWrapped()
                            },
                          },
                        },
                        [_c("span", [_vm._v("Search")])]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "flex-fill overflow-y-auto" },
                [
                  _vm.loading["status"]
                    ? _c(
                        "v-card",
                        {
                          staticClass:
                            "pa-6 d-flex align-center justify-center",
                          attrs: { flat: "" },
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "primary" },
                          }),
                        ],
                        1
                      )
                    : _vm._l(_vm.essays, function (essay, index) {
                        return _c(
                          "v-card",
                          {
                            key: index,
                            staticClass:
                              "pa-6 d-flex align-center border-0 border-b border-solid border-default",
                            attrs: { flat: "" },
                          },
                          [
                            _c("v-simple-checkbox", {
                              staticClass: "mr-3",
                              attrs: {
                                color: "primary",
                                value: _vm.selected === essay.id,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectEssay(essay)
                                },
                              },
                            }),
                            _c("essay-viewer", {
                              staticClass: "pa-0",
                              staticStyle: { "max-width": "960px" },
                              attrs: {
                                "essay-content": essay,
                                outlined: false,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "pa-3" },
                [
                  _c("v-pagination", {
                    attrs: { length: _vm.pageCount, "total-visible": 7 },
                    model: {
                      value: _vm.page,
                      callback: function ($$v) {
                        _vm.page = $$v
                      },
                      expression: "page",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }