// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@media screen and (max-width: 360px) {\n.v-slide-group__prev {\n    display: none !important;\n}\n}\n@media screen and (max-width: 360px) {\n.v-slide-group__next {\n    display: none !important;\n}\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
